export const router = {
  currentUser: "/Permissions/CurrentUser",
  userImage: "/Colleague/UserImage",
  tenants: "/Tenants",
  switchTenant: "/Tenants/SwitchTenant",
  locationsWithShared: "/Admin/LocationsWithShared",
  floorPlans: "/Admin/Floorplans",
  meeting: "/Analytics/MeetingRooms",
  desks: "/Analytics/desks",
  workTime: "/Analytics/WorkTime",
  catering: "/Analytics/Catering",
  floorplans: "/Floorplans",
  meetingRoom: "/MeetingRoom",
  department: "/Department",
  companies: "/Analytics/Companies",
  products: "/Catering/Products",
  meetingHeatmap: "/Analytics/Meetingrooms/Heatmap",
  deskHeatmap: "/Analytics/Desks/Heatmap",
  area: "/Analytics/Desks/Heatmap/Area",
  status: "/Worktime/Admin/Users/Status",
  reminder: "/Worktime/Admin/Users/SendReminder",
  violationTypes: "/Worktime/ViolationTypes",
  elevenHourRule: "/Analytics/WorkTime/Violations/11HourRule",
  fortyEightHours: "/Analytics/WorkTime/Violations/48Hours",
  adminDepartments: "/Admin/Permissions/Departments"
};
