import React from "react"
import { ClipLoader } from "react-spinners"
import { useSelector } from "react-redux"
import "./Loader.css"

export default function Loader() {
    const { Theme } = useSelector((state) => state.APIResponse.AppThemeData)

    return (
        <div className="loader position-fixed">
            <ClipLoader color={`#${Theme?.AppThemeColor ? Theme?.AppThemeColor : "000"}`} loading={true} size={150} />
        </div>
    )
}
