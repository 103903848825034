import React from "react"

export default function Error({ code, message }) {
  return (
    <div className="container">
      <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
        <h1>{code}</h1>
        <h2>{message}</h2>

        <img
          src="assets/img/401.png"
          className="img-fluid py-5"
          alt="unauthorized"
        />
      </section>
    </div>
  )
}
