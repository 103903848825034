import React, { useEffect, useRef } from "react"
import Chart from "chart.js/auto"
import moment from "moment"

export default function CurveLineChart({ graphToolTipLabel, graphLabel, graphData }) {
  const chartRef = useRef()
  const chartInstanceRef = useRef()

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }

      chartInstanceRef.current = new Chart(chartRef.current, {
        type: "line",
        data: {
          labels: graphLabel ? graphLabel.map((label) => moment(label, "MMMM Do").format("MMM Do")) : [],
          datasets: [
            {
              label: graphToolTipLabel ? graphToolTipLabel : "",
              data: graphData ? graphData : [],
              backgroundColor: ["#147ad6"],
              borderWidth: 3,
              borderColor: ["#147ad6"]
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.5
            }
          },

          plugins: {
            legend: { display: false },
          },
        },
      })
    }
  }, [graphLabel, graphData])

  return (
    <canvas ref={chartRef} id="meeting-room-usage" />
  )
}