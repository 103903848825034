import React, { useEffect, useRef } from "react"
import Chart from "chart.js/auto"

export default function LineChart({ graphToolTipLabel, graphLabel, graphData }) {
  const chartRef = useRef()

  const chartInstanceRef = useRef()

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }

      chartInstanceRef.current = new Chart(chartRef.current, {
        type: "line",
        data: {
          labels: graphLabel ? graphLabel : [],
          datasets: [
            {
              label: graphToolTipLabel ? graphToolTipLabel : "",
              data: graphData ? graphData : [],
              backgroundColor: ["rgba(254, 240, 222, 1)"],
              borderWidth: 1,
              fill: true,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,

          plugins: {
            legend: { display: false },
          },
          scales: {
            x: {
              grid: {
                display: true,
              },
              ticks: {
                autoSkip: true,
                minRotation: 50,
                maxTicksLimit: 15,
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  if (Number.isInteger(value)) {
                    return value
                  }
                },
              },
            },
          },
        },
      })
    }
  }, [graphToolTipLabel, graphLabel, graphData])

  return (
    <canvas ref={chartRef} id="meeting-room-usage" />
  )
}