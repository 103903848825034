import React, { useCallback, useEffect, useMemo, useState } from "react"
import { getDesksAnalytics } from "../../services/ApiServices"
import { sortResponseByKey } from "../../const"
import { useSelector } from "react-redux"
import StackedBarChart from "../Charts/StackedBarChart/StackedBarChart"
import LineChart from "../Charts/LineChart/LineChart"
import BarChart from "../Charts/BarChart/BarChart"
import HeatMap from "../HeatMap/HeatMap"
import Loader from "../Loader/Loader"
import moment from "moment"

export default function Desk() {
  const [isLoading, setIsLoading] = useState(false)
  const [deskAnalytics, setDeskAnalytics] = useState({})
  const ApiObjectState = useSelector((state) => state?.APIResponse?.ApiObject)

  const data = useMemo(() => {
    const data = {
      FromDate: ApiObjectState?.fromDate,
      ToDate: ApiObjectState?.toDate,
      locationIds: ApiObjectState?.locationIds,
      floorplanIds: ApiObjectState?.floorplanIds,
      areaIds: ApiObjectState?.areaIds,
      departments: ApiObjectState?.departments
    }
    return data
  }, [ApiObjectState])

  // GET DESK ANALYTICS DATA
  const fetchDeskAnalytics = useCallback((data) => {
    let subscribed = true

    if (data && (data.FromDate || data.ToDate || data.locationIds || data.floorplanIds || data.areaIds || data.departments)) {

      setIsLoading(true)
      getDesksAnalytics(data).then((response) => {
        if (subscribed) {
          setDeskAnalytics(response)
        }
      }).catch((error) => {
        if (subscribed) {
          setDeskAnalytics({})
          console.warn(error)
        }
      }).finally(() => {
        if (subscribed) {
          setIsLoading(false)
        }
      })
    }

    return () => { subscribed = false }
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchDeskAnalytics(data)
    }, 100)

    return () => clearTimeout(timeoutId)
  }, [data, fetchDeskAnalytics])


  return (
    <>
      {isLoading && <Loader />}

      <div className="tab-pane fade show active">
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-6">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Desk bookings</div>
                <div className="digit">
                  {deskAnalytics && deskAnalytics?.SeatsBooked
                    ? deskAnalytics?.SeatsBooked
                    : 0
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Guest bookings</div>
                <div className="digit">
                  {deskAnalytics && deskAnalytics?.GuestsInvited
                    ? deskAnalytics?.GuestsInvited
                    : 0
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Lunch bookings</div>
                <div className="digit">
                  {deskAnalytics && deskAnalytics?.LunchReserved
                    ? deskAnalytics?.LunchReserved
                    : 0
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Parking bookings</div>
                <div className="digit">
                  {deskAnalytics && deskAnalytics?.ParkingReserved
                    ? deskAnalytics?.ParkingReserved
                    : 0
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ======== Charts Start ======== */}
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body p-4 h300">
                <div className="row mb-1">
                  <div className="col-12">
                    <h4 className="mb-0">Area capacity usage</h4>
                  </div>
                </div>
                <StackedBarChart
                  graphToolTipCapacityLabel={"Desk area capacity"}
                  graphToolTipUtilizationLabel={"Desk area usage"}
                  graphData={
                    deskAnalytics &&
                      deskAnalytics?.UtilizationAreas &&
                      deskAnalytics?.UtilizationAreas?.Items?.length > 0
                      ? sortResponseByKey(deskAnalytics?.UtilizationAreas?.Items, "Name")
                      : []
                  }
                />
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body p-4 h300">
                <div className="row mb-1">
                  <div className="col-6">
                    <h4 className="mb-0">Desk area usage</h4>
                  </div>
                  <div className="col-6">
                    <h2 className="text-end f300  mb-0">
                      <div className="smallTxt d-inline-block pr-2">
                        Average&nbsp;&nbsp;
                      </div>
                      <span>
                        {deskAnalytics &&
                          deskAnalytics?.UtilizationDesks &&
                          deskAnalytics?.UtilizationDesks?.Average
                          ? deskAnalytics?.UtilizationDesks?.Average + "%"
                          : 0 + "%"
                        }
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="w-100" style={{ height: "215px" }}>
                  <LineChart
                    graphToolTipLabel={"Desk area usage"}
                    graphLabel={
                      deskAnalytics &&
                        deskAnalytics?.UtilizationDesks &&
                        deskAnalytics?.UtilizationDesks?.Items?.length > 0
                        ? deskAnalytics?.UtilizationDesks?.Items?.map((d) => moment(d?.Date).format("MMM Do"))
                        : []
                    }
                    graphData={
                      deskAnalytics &&
                        deskAnalytics?.UtilizationDesks &&
                        deskAnalytics?.UtilizationDesks?.Items?.length > 0
                        ? deskAnalytics?.UtilizationDesks?.Items?.map((d) => d?.Utilization)
                        : []
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6">
            <div className="card">
              <div className="card-body p-4 h250">
                <div className="row  mb-0">
                  <div className="col-8">
                    <h4 className="mb-0">Hourly usage</h4>
                  </div>
                  <div className="col-4">
                    <h2 className="text-end f300">
                      {deskAnalytics && (
                        deskAnalytics?.UtilizationHourly &&
                          deskAnalytics?.UtilizationHourly?.Average
                          ? deskAnalytics?.UtilizationHourly?.Average + "%"
                          : 0 + "%"
                      )}
                    </h2>
                  </div>
                </div>
                <div className="w-100" style={{ height: "158px" }}>
                  <BarChart
                    graphToolTipLabel={"Hourly usage"}
                    graphLabel={
                      deskAnalytics && (
                        deskAnalytics?.UtilizationHourly &&
                          deskAnalytics?.UtilizationHourly?.Items?.length > 0
                          ? deskAnalytics?.UtilizationHourly?.Items?.map((d) => d?.Hour)
                          : []
                      )}
                    graphData={
                      deskAnalytics && (
                        deskAnalytics?.UtilizationHourly &&
                          deskAnalytics?.UtilizationHourly?.Items?.length > 0
                          ? deskAnalytics?.UtilizationHourly?.Items?.map((d) => d?.Utilization)
                          : []
                      )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6">
            <div className="card">
              <div className="card-body p-4 h250">
                <div className="row  mb-0">
                  <div className="col-8">
                    <h4 className="mb-0">Weekday usage</h4>
                  </div>
                  <div className="col-4">
                    <h2 className="text-end f300">
                      {deskAnalytics &&
                        deskAnalytics?.UtilizationWeekday &&
                        deskAnalytics?.UtilizationWeekday?.Average
                        ? deskAnalytics?.UtilizationWeekday?.Average + "%"
                        : 0 + "%"
                      }
                    </h2>
                  </div>
                </div>
                <div className="w-100" style={{ height: "158px" }}>
                  <BarChart
                    graphToolTipLabel={"Weekday usage"}
                    graphLabel={
                      deskAnalytics && (
                        deskAnalytics?.UtilizationWeekday &&
                          deskAnalytics?.UtilizationWeekday?.Items?.length > 0
                          ? deskAnalytics?.UtilizationWeekday?.Items?.map((d) => d?.Weekday)
                          : []
                      )}
                    graphData={
                      deskAnalytics &&
                        deskAnalytics?.UtilizationWeekday &&
                        deskAnalytics?.UtilizationWeekday?.Items &&
                        deskAnalytics?.UtilizationWeekday?.Items?.length > 0
                        ? deskAnalytics?.UtilizationWeekday?.Items?.map((d) => d?.Utilization)
                        : []
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------- Charts End -------- */}

        <HeatMap Title={"Desk heat map"} renderImage={"DeskSeats"} />
      </div>
    </>
  )
}
