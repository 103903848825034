import React, { useEffect, useState } from "react"
import { getTenants, getUserImage, switchTenant } from "../../services/ApiServices"
import { changeAuthenticated } from "../../store/slices/MSALSlice"
import { useDispatch, useSelector } from "react-redux"
import { loginRequest } from "../../authConfig"
import { useMsal } from "@azure/msal-react"
import { toast } from "react-hot-toast"
import { Link } from "react-router-dom"
import "./Header.css"

export default function Header() {
  const { instance } = useMsal()

  const userName = useSelector((state) => state.MSALAuth.userName)
  const userEmail = useSelector((state) => state.MSALAuth.userEmail)
  const { AppLogo } = useSelector((state) => state.APIResponse.AppThemeData)
  const [userImage, setUserImage] = useState("")

  const [allTenants, setAllTenants] = useState([])
  const [selectedTenant, setSelectedTenant] = useState(0)

  const dispatch = useDispatch()

  const handleLogout = () => {
    instance.logoutRedirect(loginRequest);
    dispatch(changeAuthenticated(false))
  }

  const SwitchTenant = async (id) => {
    if (id) {
      await switchTenant(id).then(() => {
        window.location.reload()
      })
    }
  }

  useEffect(() => {
    if (userEmail) {
      getUserImage(userEmail).then((response) => {
        setUserImage(response && response?.Image ? response?.Image : "")
      }).catch((error) => {
        console.warn(error)
        setUserImage("")
      })
    }
  }, [userEmail])

  // Get All Tenants
  useEffect(() => {
    getTenants().then((response) => {
      setAllTenants(response && response.length > 0 ? response : [])
      response && response.length > 0 && setSelectedTenant(response.filter((d) => d?.IsCurrent === true)[0]?.TenantId)
    }).catch((error) => {
      toast.error(error)
    })
  }, [])

  return (
    <header id="header" className="header d-flex  align-items-center">
      <div className="d-flex ">
        <Link className="logo d-flex">
          {AppLogo?.Image ?
            <img src={AppLogo?.Image} alt="" /> :
            <img src="assets/img/logo-white.svg" style={{ maxHeight: "24px" }} alt="" />
          }
        </Link>
      </div>

      <div className="title-bar">Analytics</div>

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item dropdown">
            <div className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown">
              <i className="bi bi-gear me-2" style={{ fontSize: 24 }} />
              <span className="d-none d-md-block dropdown-toggle me-2">Hi, {userName}</span>
              <img src={userImage ? userImage : "assets/img/user-thumbnail.png"} alt="Profile" className="rounded-circle" />
            </div>
            <ul className="dropdown-menu dropdown-menu-arrow p-0">
              <li className="dropdown-header">
                <h5 className="user-name"><b>{userName}</b></h5>
                <span className="user-email">{userEmail}</span>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li className="dropdown-item d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#switchTenantModal">
                <i className="bi bi-arrow-left-right" />
                <span>Switch Tenant</span>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li onClick={() => handleLogout()} className="dropdown-item d-flex align-items-center">
                <i className="bi bi-box-arrow-right" />
                <span>Sign Out</span>
              </li>
            </ul>
          </li>
        </ul>
      </nav>


      {/* ============================= Switch Tenant Modal ============================= */}
      <div className="modal fade" id="switchTenantModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Switch Tenant</h5>
              <i onClick={() => setSelectedTenant(allTenants.filter((d) => d?.IsCurrent === true)[0]?.TenantId)} className="fa-solid fa-xmark" data-bs-dismiss="modal" />
            </div>
            <div className="modal-body">
              <label className="input-label">Tenants</label>
              <select value={selectedTenant} onChange={(e) => setSelectedTenant(Number(e.target.value))} className="form-select">
                <option value={0}>Select Tenant</option>
                {allTenants && allTenants.length > 0 &&
                  allTenants.map((tenant, tenantIndex) =>
                    <option key={tenantIndex} value={tenant?.TenantId}>{tenant?.TenantName}</option>
                  )
                }
              </select>
            </div>
            <div className="modal-footer">
              <button onClick={() => setSelectedTenant(allTenants.filter((d) => d?.IsCurrent === true)[0]?.TenantId)} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button onClick={() => SwitchTenant(selectedTenant)} className="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
            </div>
          </div>
        </div>
      </div>

    </header>
  )
}
