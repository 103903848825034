import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeAPIObject } from "../../../store/slices/APIResponseSlice"
import Highcharts from "highcharts"
import "./StackedBarChart.css"

export default function StackedBarChart({ graphToolTipCapacityLabel, graphToolTipUtilizationLabel, graphData }) {
    const [capacityData, setCapacityData] = useState([])
    const [utilizationData, setUtilizationData] = useState([])
    const [pageStep, setPageStep] = useState(0)

    const dispatch = useDispatch()

    const ApiObjectState = useSelector((state) => state?.APIResponse?.ApiObject)

    const onBarClick = (id) => {
        dispatch(changeAPIObject({
            ...ApiObjectState,
            areaIds: [id]
        }))
    }

    useEffect(() => {
        setPageStep(0)
    }, [graphData])

    useEffect(() => {
        const data = graphData?.slice(10 * pageStep, 10 * (pageStep + 1))
        setCapacityData(data?.map((d, i) => ({ name: d?.Name, y: d?.CapacityTotal, x: data?.length - i, index: data?.length - i, Id: d?.AreaId })))
        setUtilizationData(data?.map((d, i) => ({ name: d?.Name, y: d?.BookingsTotal, percentage: d?.Utilization, x: data?.length - i, index: data?.length - i, Id: d?.AreaId })))
    }, [pageStep, graphData])

    useEffect(() => {
        Highcharts.chart("high-chart-div", {
            boost: {
                enabled: true,
                useGPUTranslations: true,
                seriesThreshold: 30
            },
            chart: { type: "column", height: 220 },
            title: { text: null },
            plotOptions: {
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: function () {
                                onBarClick(this.Id)
                            }
                        }
                    },
                    grouping: false,
                    borderWidth: 0,
                    dataSorting: {
                        enabled: true,
                        sortKey: "x"
                    },
                }
            },

            legend: { enabled: false },
            xAxis: {
                type: "category",
                // max: 9,
                accessibility: { description: "Areas" },
                labels: {
                    useHTML: true,
                    animate: true,
                    rotation: 0,
                    style: { textAlign: "center", fontSize: "x-small" },
                }
            },
            tooltip: { shared: true, style: { fontSize: "x-small" } },
            yAxis: [{ title: null, }],
            series: [
                {
                    name: graphToolTipCapacityLabel,
                    linkedTo: "CapacityUtilization",
                    color: "rgba(158, 159, 163, 0.5)",
                    pointPlacement: -0.2,
                    data: capacityData,
                },
                {
                    name: graphToolTipUtilizationLabel,
                    id: "CapacityUtilization",
                    color: "#147ad6",
                    dataSorting: { enabled: true, matchByName: true },
                    dataLabels: [{
                        enabled: true,
                        inside: false,
                        formatter: function () { return this?.percentage + "%" },
                        style: { fontSize: "x-small" }
                    }],
                    data: utilizationData,
                }],
            exporting: { allowHTML: true }
        })
    }, [graphToolTipCapacityLabel, graphToolTipUtilizationLabel, capacityData, utilizationData])

    return (
        <>
            <div className="w-100 overflow-hidden" style={{ height: "207px" }}>
                <div id="high-chart-div" />
            </div>
            <div className="d-flex justify-content-end gap-3">
                <button
                    onClick={() => setPageStep((prev) => prev - 1)}
                    disabled={pageStep === 0}
                    className="btn btn-primary prev py-0 px-2"
                >
                    {`<`}
                </button>
                <button
                    onClick={() => setPageStep((prev) => prev + 1)}
                    disabled={pageStep + 1 >= graphData?.length / 10}
                    className="btn btn-primary next py-0 px-2"
                >
                    {`>`}
                </button>
            </div>
        </>
    )
}
