import React, { useEffect } from "react";
import "./DoughnutChart.css";

export default function DoughnutChart({ progress }) {
  // Circular Progress Bar
  // Get a reference from this👇
  // https://codepen.io/zslabs/pen/yjdNZJ

  useEffect(() => {
    const meters = document.querySelectorAll("svg[data-value] .meter")

    meters.forEach((path) => {
      const length = path.getTotalLength()
      const value = parseFloat(path.parentNode.getAttribute("data-value"))
      const to = length * ((100 - value) / 100)
      path.style.strokeDashoffset = Math.max(0, to)
      path.nextElementSibling.textContent = value + "%"
    })
  }, [progress])

  return (
    <svg className="circular-progress-bar" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" data-value={progress ? progress : 0}>
      <circle r={45} cx={50} cy={50} />
      <path
        className="meter"
        d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDashoffset="282.78302001953125"
        strokeDasharray="282.78302001953125"
      />
      <text
        x={50}
        y={50}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={15}
      />
    </svg>
  );
}
