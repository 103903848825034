import { configureStore } from "@reduxjs/toolkit"
import APIResponseSlice from "./slices/APIResponseSlice"
import MSALSlice from "./slices/MSALSlice"

export const store = configureStore({
  reducer: {
    MSALAuth: MSALSlice,
    APIResponse: APIResponseSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})