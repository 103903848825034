import React from "react"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig } from "./authConfig"
import { Toaster } from "react-hot-toast"
import { Provider } from "react-redux"
import { store } from "./store/store"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"

const msalInstance = new PublicClientApplication(msalConfig)
await msalInstance.initialize()

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
      <Toaster position="top-right" reverseOrder={false} gutter={8} />
    </MsalProvider>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()