import React, { useEffect, useState } from "react"
import { getMeetingAnalytics } from "../../services/ApiServices"
import { useSelector } from "react-redux"
import { toast } from "react-hot-toast"
import CurveLineChart from "../Charts/CurveLineChart/CurveLineChart"
import DoughnutChart from "../Charts/DoughnutChart/DoughnutChart"
import LineChart from "../Charts/LineChart/LineChart"
import HeatMap from "../HeatMap/HeatMap"
import Loader from "../Loader/Loader"
import moment from "moment"

export default function MeetingRooms() {
  const [isLoading, setIsLoading] = useState(false)
  const [meetingAnalytics, setMeetingData] = useState(null)
  const ApiObjectState = useSelector((state) => state?.APIResponse?.ApiObject)

  useEffect(() => {
    let subscribed = true

    if (ApiObjectState) {
      let data = {
        FromDate: ApiObjectState?.fromDate,
        ToDate: ApiObjectState?.toDate,
      }

      if (ApiObjectState?.locationIds) {
        data = { ...data, locationIds: ApiObjectState?.locationIds }
      }
      if (ApiObjectState?.floorplanIds) {
        data = { ...data, floorplanIds: ApiObjectState?.floorplanIds }
      }
      if (ApiObjectState?.meetingRoomIds) {
        data = { ...data, meetingRoomIds: ApiObjectState?.meetingRoomIds }
      }

      setIsLoading(true)
      getMeetingAnalytics(data).then((response) => {
        if (subscribed) {
          setMeetingData(response)
        }
      }).catch((error) => {
        if (subscribed) {
          toast.error(error)
        }
      }).finally(() => {
        if (subscribed) {
          setIsLoading(false)
        }
      })
    }

    return () => { subscribed = false }
  }, [ApiObjectState])

  return (
    <>
      {isLoading && <Loader />}
      <div className="tab-pane fade show active">
        {/* Top Three Card */}
        <div className="row">
          {/* Meeting Booked */}
          <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Meeting booked</div>
                <div className="digit">{meetingAnalytics?.MeetingsBooked ? meetingAnalytics?.MeetingsBooked : 0}</div>
              </div>
            </div>
          </div>

          {/* Meeting Time */}
          <div className="col-xl-4 col-lg-6 col-md-6">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Meeting time (hours)</div>
                <div className="digit">{meetingAnalytics?.MeetingsHours ? meetingAnalytics?.MeetingsHours : 0}</div>
              </div>
            </div>
          </div>

          {/* Catering Ordered */}
          <div className="col-xl-4 col-lg-12 col-md-12">
            <div className="card counts shadownone">
              <div className="card-body p-4">
                <div className="title mb-1">Catering ordered</div>
                <div className="digit">{meetingAnalytics?.CateringOrdered ? meetingAnalytics?.CateringOrdered : 0}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Meeting Room Usage */}
        <div className="card">
          <div className="card-body p-4 h300">
            <div className="row mb-1">
              <div className="col-8">
                <h4 className="mb-0">Meeting room usage</h4>
              </div>
              <div className="col-4">
                <h2 className="text-end f300 mb-0">
                  <div className="smallTxt d-inline-block pr-2">Average&nbsp;&nbsp;</div>
                  <span>
                    {meetingAnalytics && meetingAnalytics?.UtilizationMeetingRooms
                      ? meetingAnalytics?.UtilizationMeetingRooms?.Average + "%"
                      : 0 + "%"}
                  </span>
                </h2>
              </div>
            </div>
            <div className="w-100" style={{ height: "215px" }}>
              <LineChart
                graphToolTipLabel={"Meeting room usage"}
                graphLabel={
                  meetingAnalytics &&
                    meetingAnalytics?.UtilizationMeetingRooms &&
                    meetingAnalytics?.UtilizationMeetingRooms?.Items &&
                    meetingAnalytics?.UtilizationMeetingRooms?.Items.length > 0
                    ? meetingAnalytics?.UtilizationMeetingRooms?.Items.map(
                      (d) => {
                        return moment(d?.Date).format("MMMM Do")
                      }
                    )
                    : []
                }
                graphData={
                  meetingAnalytics &&
                    meetingAnalytics?.UtilizationMeetingRooms &&
                    meetingAnalytics?.UtilizationMeetingRooms?.Items &&
                    meetingAnalytics?.UtilizationMeetingRooms?.Items.length > 0
                    ? meetingAnalytics?.UtilizationMeetingRooms?.Items.map(
                      (d) => {
                        return d?.Utilization
                      }
                    )
                    : []
                }
              />
            </div>
          </div>
        </div>

        <div className="row">
          {/* Meeting Room Behavior */}
          <div className="col-xl-3 col-lg-12 col-md-12">
            <div className="card">
              <div className="card-body p-4 h300">
                <h4 className="mb-3">Meeting room behavior</h4>
                <div className="d-flex justify-content-center mb-2" style={{ height: "149px" }}>
                  <DoughnutChart
                    progress={
                      meetingAnalytics &&
                      meetingAnalytics?.MeetingsCancelled &&
                      meetingAnalytics?.MeetingsCancelled?.Percentage
                    }
                  />
                </div>
                <div className="text-center w-100">
                  <small>
                    <b>
                      {meetingAnalytics && meetingAnalytics?.MeetingsCancelled
                        ? meetingAnalytics?.MeetingsCancelled?.MeetingsCancelled
                        : 0}
                    </b>
                    &nbsp;out of&nbsp;
                    <b>
                      {meetingAnalytics && meetingAnalytics?.MeetingsCancelled
                        ? meetingAnalytics?.MeetingsCancelled?.MeetingsTotal
                        : 0}
                    </b>
                  </small>
                  <br />
                  <small className="">Cancelled by user</small>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-9 col-lg-12 col-md-12">
            <div className="row">
              {/* Average Length */}
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="card">
                  <div className="card-body p-4 h300">
                    <div className="row  mb-3">
                      <div className="col-8">
                        <h4 className="mb-0">Average length</h4>
                        <div className="smallTxt">Hours per meeting</div>
                      </div>
                      <div className="col-4">
                        <h2 className="text-end f300">
                          {meetingAnalytics && meetingAnalytics?.HourPerMeeting
                            ? meetingAnalytics?.HourPerMeeting?.Average
                            : 0}
                        </h2>
                      </div>
                    </div>
                    <div className="w-100 d-flex justify-content-center" style={{ height: "195px" }}>
                      <CurveLineChart
                        graphToolTipLabel={"Hours per meeting"}
                        graphLabel={
                          meetingAnalytics &&
                            meetingAnalytics?.HourPerMeeting &&
                            meetingAnalytics?.HourPerMeeting?.Items &&
                            meetingAnalytics?.HourPerMeeting?.Items.length > 0
                            ? meetingAnalytics?.HourPerMeeting?.Items.map((d) => {
                              return moment(d?.Date).format("MMMM Do")
                            })
                            : []
                        }
                        graphData={
                          meetingAnalytics &&
                            meetingAnalytics?.HourPerMeeting &&
                            meetingAnalytics?.HourPerMeeting?.Items &&
                            meetingAnalytics?.HourPerMeeting?.Items.length > 0
                            ? meetingAnalytics?.HourPerMeeting?.Items.map((d) => {
                              return d?.Hours
                            })
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Average Attendees */}
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="card">
                  <div className="card-body p-4 h300">
                    <div className="row  mb-3">
                      <div className="col-8">
                        <h4 className="mb-0">Average attendees</h4>
                        <div className="smallTxt">Peoples per meeting</div>
                      </div>
                      <div className="col-4">
                        <h2 className="text-end f300">
                          {meetingAnalytics &&
                            meetingAnalytics?.AttendeesPerMeeting
                            ? meetingAnalytics?.AttendeesPerMeeting?.Average
                            : 0}
                        </h2>
                      </div>
                    </div>
                    <div className="w-100 d-flex justify-content-center" style={{ height: "195px" }}>
                      <CurveLineChart
                        graphToolTipLabel={"Peoples per meeting"}
                        graphLabel={
                          meetingAnalytics &&
                            meetingAnalytics?.AttendeesPerMeeting &&
                            meetingAnalytics?.AttendeesPerMeeting?.Items &&
                            meetingAnalytics?.AttendeesPerMeeting?.Items.length > 0
                            ? meetingAnalytics?.AttendeesPerMeeting?.Items.map(
                              (d) => {
                                return moment(d?.Date).format("MMMM Do")
                              }
                            )
                            : []
                        }
                        graphData={
                          meetingAnalytics &&
                            meetingAnalytics?.AttendeesPerMeeting &&
                            meetingAnalytics?.AttendeesPerMeeting?.Items &&
                            meetingAnalytics?.AttendeesPerMeeting?.Items.length > 0
                            ? meetingAnalytics?.AttendeesPerMeeting?.Items.map(
                              (d) => {
                                return d?.Count
                              }
                            )
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HeatMap Title={"Meeting room heat map"} renderImage={"MeetingRoom"} />
      </div>
    </>
  )
}
