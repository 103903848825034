import React, { useEffect, useMemo, useState } from "react"
import { changeActivePanel, changeAPIObject } from "../../store/slices/APIResponseSlice"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import "./Home.css"

import SideBar from "../SideBar/SideBar"
import MeetingRooms from "../MeetingRooms/MeetingRooms"
import Desk from "../Desk/Desk"
import WorkTimeNewDesign from "../WorkTimeNewDesign/WorkTimeNewDesign"
import Catering from "../Catering/Catering"
import { appVersion } from "../../const"

export default function Home() {
  const activePanel = useSelector((state) => state.APIResponse.ActivePanel) // "Meeting" || "Desk" || "WorkTime" || "Catering"
  const hasMeetingRoomPermission = useSelector((state) => state.APIResponse.EnableMeetingRoom)
  const hasDeskBookingPermission = useSelector((state) => state.APIResponse.EnableDeskBooking)
  const hasWorkTimePermission = useSelector((state) => state.APIResponse.EnableWorkTime)
  const hasCateringPermission = useSelector((state) => state.APIResponse.EnableCanteen)

  const [inputDate, setInputDate] = useState({ From: moment().subtract(1, "M").format("YYYY-MM-DD"), To: moment().format("YYYY-MM-DD") })

  const dispatch = useDispatch()

  const allRegistry = useMemo(() => ([
    {
      title: "Meeting Rooms",
      value: "Meeting",
      hasRequiredPermission: hasMeetingRoomPermission,
      component: () => <MeetingRooms />,
    },
    {
      title: "Desks",
      value: "Desk",
      hasRequiredPermission: hasDeskBookingPermission,
      component: () => <Desk />,
    },
    {
      title: "WorkTime",
      value: "WorkTime",
      hasRequiredPermission: hasWorkTimePermission,
      component: () => <WorkTimeNewDesign />,
    },
    {
      title: "Catering",
      value: "Catering",
      hasRequiredPermission: hasCateringPermission,
      component: () => <Catering />,
    },
  ]), [hasCateringPermission, hasDeskBookingPermission, hasMeetingRoomPermission, hasWorkTimePermission])

  const activeRegistryData = useMemo(() => (
    (allRegistry ?? [])?.find((registry) => registry?.hasRequiredPermission && registry?.value === activePanel)
  ), [allRegistry, activePanel])

  // Set To date after From date if To date is set before From date
  useEffect(() => {
    if (moment(inputDate?.To).isBefore(moment(inputDate?.From))) {
      setInputDate((Prev) => ({ ...Prev, To: inputDate?.From }))
    }
  }, [inputDate?.From, inputDate?.To])

  return (
    <main id="main" className="main overflow-hidden position-relative" style={{ paddingBottom: 30 }}>
      <section className="section">
        <div className="row">
          <SideBar inputDate={inputDate} setInputDate={setInputDate} />

          <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
            <div className="card">
              <div className="card-body p-4">
                <ul className="nav nav-tabs">
                  {allRegistry && allRegistry?.length > 0 && (
                    allRegistry?.map((registry, registryIndex) =>
                      registry?.hasRequiredPermission && (
                        <li key={registryIndex} className="nav-item">
                          <button
                            onClick={() => {
                              if (registry?.value === "WorkTime") {
                                setInputDate({
                                  From: moment().startOf("isoWeek").format("YYYY-MM-DD"),
                                  To: moment().endOf("isoWeek").format("YYYY-MM-DD"),
                                });
                                dispatch(changeAPIObject((prev) => ({
                                  ...(prev ?? {}),
                                  fromDate: moment().startOf("isoWeek").format("YYYY-MM-DD"),
                                  toDate: moment().endOf("isoWeek").format("YYYY-MM-DD"),
                                })))
                              } else {
                                const newInputDateToBeSet = { From: moment().subtract(1, "M").format("YYYY-MM-DD"), To: moment().format("YYYY-MM-DD") }
                                if (JSON.stringify(newInputDateToBeSet) !== JSON.stringify(inputDate)) {
                                  setInputDate(newInputDateToBeSet)
                                }
                              }
                              dispatch(changeActivePanel(registry?.value))
                            }}
                            className={`nav-link ${activePanel === registry?.value ? "active" : ""}`}
                          >
                            <span>{registry?.title}</span>
                          </button>
                        </li>
                      )
                    )
                  )}
                </ul>
              </div>
            </div>

            {activeRegistryData && activeRegistryData?.component && <activeRegistryData.component />}
          </div>
        </div>
      </section>

      <div
        className="position-absolute py-1"
        style={{ paddingRight: 30, right: 0, bottom: 0, zIndex: 9999, fontSize: "small" }}
      >
        <small>{appVersion}</small>
      </div>
    </main>
  )
}
