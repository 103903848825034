import React, { useEffect, useState } from "react"
import { getAreaMap, getDeskHeatmapData, getFloorPlanMap, getMeetingHeatmapData, getSeats, getSeatsData } from "../../services/ApiServices"
import { toast } from "react-hot-toast"
import { useSelector } from "react-redux"
import { ClipLoader } from "react-spinners"
import { Tooltip } from "react-tooltip"
import moment from "moment"
import "./HeatMap.css"

export default function HeapMap({ Title, renderImage }) {
    const { Theme } = useSelector((state) => state.APIResponse.AppThemeData)
    const allFloorPlans = useSelector((state) => state.APIResponse.AllFloorPlans)
    const ApiObjectState = useSelector((state) => state?.APIResponse?.ApiObject)

    const [isLoading, setIsLoading] = useState(false)
    const [filteredFloorplan, setFilteredFloorplan] = useState(allFloorPlans)

    const [inputFloorPlan, setInputFloorPlan] = useState(0)
    const [selectedFloorPlan, setSelectedFloorPlan] = useState({})

    const [floorPlanMap, setFloorPlanMap] = useState({ Image: "", naturalHeight: 0, naturalWidth: 0 })
    const [meetingHeatmapData, setMeetingHeatmapData] = useState([])

    const [areaMap, setAreaMap] = useState({ Image: "", naturalHeight: 0, naturalWidth: 0 })
    const [deskHeatmapData, setDeskHeatmapData] = useState([])
    const [allSeats, setAllSeats] = useState([])
    const [seatsData, setSeatsData] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedArea, setSelectedArea] = useState(0)

    const getColorCodedTooltipClassName = (value, prefixClass) => {
        if (value <= 50) {
            return `${prefixClass}-success`
        } else if (value > 50 && value <= 75) {
            return `${prefixClass}-warning`
        } else if (value > 75) {
            return `${prefixClass}-danger`
        } else {
            return `${prefixClass}-primary`
        }
    }

    const renderMeetingTooltip = (data, i, meetingHeatmapData) => {
        if (data && data?.RoomId) {
            const tooltip = meetingHeatmapData?.length > 0 ? meetingHeatmapData?.find((d) => d?.MeetingRoomId === data?.RoomId) : undefined

            return tooltip && (
                <div
                    key={i} className="tool-tip-wrapper"
                    style={{
                        left: `calc(${(data?.XCoord * 100) / floorPlanMap?.naturalWidth}% - 15px)`,
                        top: `calc(${(data?.YCoord * 100) / floorPlanMap?.naturalHeight}% - 87px)`,
                    }}
                >
                    <div className={`tool-tip ${getColorCodedTooltipClassName(tooltip?.AverageAttendees, "tool-tip")} w-100`}>
                        <div className="d-flex align-items-center">
                            <div className="title">{data?.Name}</div>
                            <span className="usage" style={{ fontSize: "small", lineHeight: "19.5px", fontWeight: 600 }}>
                                {tooltip?.Utilization?.toString().includes(".") ? tooltip?.Utilization?.toString().replace(".", ",") : tooltip?.Utilization}%
                            </span>
                        </div>
                        <div className="tool-tip-details-section">
                            <div className="tool-tip-details">
                                <span className="data-label">Total Meetings</span>
                                <span className="data-value">{tooltip?.MeetingsTotal}</span>
                            </div>
                            <div className="tool-tip-details">
                                <span className="data-label">Average meeting time</span>
                                <span className="data-value">{tooltip?.AverageMeetingTimeMinutes}</span>
                            </div>
                            <div className="tool-tip-details">
                                <span className="data-label">Average Attendees</span>
                                <span className="data-value badge">{tooltip?.AverageAttendees.toString().replace(".", ",")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const renderDeskTooltip = (data, i, deskHeatmapData) => {
        if (data && data?.Area && data?.Area?.Id) {
            const tooltip = deskHeatmapData?.length > 0 ? deskHeatmapData?.find((d) => d?.AreaId === data?.Area?.Id) : {}
            const utilization = tooltip?.Utilization?.toString().includes(".") ? tooltip?.Utilization?.toString().replace(".", ",") : tooltip?.Utilization

            return (
                <div
                    key={i} className="tool-tip-wrapper"
                    onClick={() => {
                        GetAreaMap(data?.Area?.Id)
                        setSelectedArea(i)
                    }}
                    style={{
                        left: `calc(${(data?.XCoord * 100) / floorPlanMap?.naturalWidth}% - 15px)`,
                        top: `calc(${(data?.YCoord * 100) / floorPlanMap?.naturalHeight}% - 87px)`,
                    }}
                >
                    <div className={`tool-tip ${getColorCodedTooltipClassName(tooltip?.Utilization, "tool-tip")} w-100`}>
                        <div className="d-flex align-items-center">
                            <div className="title">{data?.Area && data?.Area?.Name}</div>
                            <span className="usage" style={{ fontSize: "small", lineHeight: "19.5px", fontWeight: 600 }}>
                                {utilization}%
                            </span>
                        </div>
                        <div className="tool-tip-details-section">
                            <div className="tool-tip-details">
                                <span className="data-label">Total Seats</span>
                                <span className="data-value">{tooltip?.SeatsTotal}</span>
                            </div>
                            <div className="tool-tip-details">
                                <span className="data-label">Utilization</span>
                                <span className="data-value highlighted">
                                    {utilization}%
                                </span>
                            </div>
                            <div className="tool-tip-details">
                                <span className="data-label">Total Bookings</span>
                                <span className="data-value badge">{tooltip?.SeatsReservedTotalReservations}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const renderSeat = (data, i, seatsData) => {
        if (seatsData?.length > 0 && data && data?.Id) {
            const seat = seatsData?.find((d) => d?.SeatId === data?.Id)
            const seatName = seat?.SeatId === data?.Id ? data?.Name : ""
            return (
                <div key={i} className={`seats ${getColorCodedTooltipClassName(seat?.Utilization, "seat")}`}
                    style={{
                        left: `calc(${(data?.XCoord * 100) / areaMap?.naturalWidth}% - 20px)`,
                        top: `calc(${(data?.YCoord * 100) / areaMap?.naturalHeight}% - 13px)`,
                    }}
                    data-tooltip-id={`seat-btn-${data?.Id}`}
                    data-tooltip-place="top"
                >
                    <span className="seat-data">
                        {seat?.Utilization?.toString()?.includes(".") ? seat?.Utilization?.toString()?.replace(".", ",") : seat?.Utilization}%
                    </span>

                    <Tooltip id={`seat-btn-${data?.Id}`} style={{ zIndex: "9999" }}>
                        <div>
                            <small>{seatName}</small>
                        </div>
                    </Tooltip>
                </div>
            )
        }
    }

    const onAreaChange = (Index) => {
        if (Index >= 0 && Index < selectedFloorPlan?.FloorplanAreas?.length) {
            GetAreaMap(selectedFloorPlan?.FloorplanAreas[Index]?.Area?.Id)
            setSelectedArea(Index)
        } else if (Index < 0) {
            GetAreaMap(selectedFloorPlan?.FloorplanAreas[selectedFloorPlan?.FloorplanAreas?.length - 1]?.Area?.Id)
            setSelectedArea(selectedFloorPlan?.FloorplanAreas?.length - 1)
        } else if (Index === selectedFloorPlan?.FloorplanAreas?.length) {
            GetAreaMap(selectedFloorPlan?.FloorplanAreas[0]?.Area?.Id)
            setSelectedArea(0)
        }
    }

    const GetAreaMap = async (Id) => {
        setIsModalOpen(true)
        if (Id) {
            try {
                setIsLoading(true)
                setAreaMap({ Image: undefined, naturalHeight: 0, naturalWidth: 0 })
                await getAreaMap(Id).then((response) => {
                    setAreaMap({ ...areaMap, Image: response && response?.Image ? response?.Image : "" })
                    GetSeats(Id)
                    setIsLoading(false)
                })
            } catch (error) {
                setAreaMap({ Image: "", naturalHeight: 0, naturalWidth: 0 })
                setIsLoading(false)
                toast.error(error)
            }
        } else {
            setIsLoading(false)
            setSelectedFloorPlan({})
            setAreaMap({ Image: "", naturalHeight: 0, naturalWidth: 0 })
        }
    }

    const GetSeats = async (Id) => {
        try {
            if (Id) {
                setAllSeats([])
                setIsLoading(true)
                await getSeats(Id, moment().format("YYYY-MM-DD")).then(async (response) => {
                    setAllSeats(response && response?.Seats ? response?.Seats : [])
                    await GetSeatsData(ApiObjectState?.fromDate, ApiObjectState?.toDate, Id)
                    setIsLoading(false)
                })
            } else {
                setAllSeats([])
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            toast.error(error)
        }
    }

    const GetSeatsData = async (fromDate, toDate, Id) => {
        if (Id) {
            try {
                setIsLoading(true)
                await getSeatsData(fromDate, toDate, Id).then((response) => {
                    setSeatsData(response && response?.Items && response?.Items.length > 0 ? response?.Items : [])
                })
            } catch (error) {
                setIsLoading(false)
                toast.error(error)
            }
        } else {
            setSeatsData([])
        }
    }

    useEffect(() => {
        if (ApiObjectState && allFloorPlans && allFloorPlans?.length > 0) {
            setFilteredFloorplan(ApiObjectState?.locationIds?.length > 0 ?
                allFloorPlans?.filter((d) => ApiObjectState?.locationIds.includes(d?.Location?.Id)) :
                allFloorPlans
            )
        }
    }, [ApiObjectState, allFloorPlans])

    useEffect(() => {
        if (filteredFloorplan && filteredFloorplan.length > 0) {
            setInputFloorPlan(filteredFloorplan[0]?.Id)
        } else {
            setInputFloorPlan(0)
        }
    }, [filteredFloorplan])

    // Get FloorPlan Map
    useEffect(() => {
        let subscribed = true

        if (inputFloorPlan !== 0) {
            setIsLoading(true)
            setFloorPlanMap({ Image: undefined, naturalHeight: 0, naturalWidth: 0 })
            getFloorPlanMap(inputFloorPlan).then((response) => {
                if (subscribed) {
                    setFloorPlanMap({ ...floorPlanMap, Image: response?.Image ? response?.Image : "" })
                    setIsLoading(false)
                }
            }).catch((error) => {
                if (subscribed) {
                    setFloorPlanMap({ Image: "", naturalHeight: 0, naturalWidth: 0 })
                    setIsLoading(false)
                    toast.error(error)
                }
            })
        } else {
            if (subscribed) {
                setIsLoading(false)
                setSelectedFloorPlan({})
                setFloorPlanMap({ Image: "", naturalHeight: 0, naturalWidth: 0 })
            }
        }

        return () => { subscribed = false }
    }, [inputFloorPlan])

    // =========================== Get Meeting HeatMap Data ===========================
    useEffect(() => {
        let subscribed = true

        if (inputFloorPlan && renderImage === "MeetingRoom") {
            setIsLoading(true)
            getMeetingHeatmapData(ApiObjectState?.fromDate, ApiObjectState?.toDate, inputFloorPlan, []).then((response) => {
                if (subscribed) {
                    setMeetingHeatmapData(response && response?.Items && response?.Items.length > 0 ? response?.Items : [])
                }
            }).catch((error) => {
                if (subscribed) {
                    setMeetingHeatmapData([])
                    toast.error(error)
                }
            }).finally(() => {
                if (subscribed) {
                    setIsLoading(false)
                }
            })
        } else {
            if (subscribed) {
                setMeetingHeatmapData([])
            }
        }

        return () => {
            subscribed = false
        }
    }, [renderImage, ApiObjectState?.fromDate, ApiObjectState?.toDate, inputFloorPlan,])

    // =========================== Get Desk HeatMap Data ===========================
    useEffect(() => {
        let subscribed = true

        if (inputFloorPlan && renderImage === "DeskSeats") {
            const requestPayload = {
                FromDate: ApiObjectState?.fromDate,
                ToDate: ApiObjectState?.toDate,
                floorplanId: inputFloorPlan,
                departments: ApiObjectState?.departments,
            }

            setIsLoading(true)
            getDeskHeatmapData(requestPayload).then((response) => {
                if (subscribed) {
                    setDeskHeatmapData(response && response?.Items && response?.Items.length > 0 ? response?.Items : [])
                }
            }).catch((error) => {
                if (subscribed) {
                    setDeskHeatmapData([])
                    toast.error(error)
                }
            }).finally(() => {
                if (subscribed) {
                    setIsLoading(false)
                }
            })
        } else {
            if (subscribed) {
                setDeskHeatmapData([])
            }
        }

        return () => {
            subscribed = false
        }
    }, [renderImage, ApiObjectState?.fromDate, ApiObjectState?.toDate, inputFloorPlan, ApiObjectState?.departments])

    return (
        <>
            <div className="card heat-map m-0">
                <div className="card-body p-4">
                    <div className="row">
                        <div className="col-xl-4 col-md-4 col-sm-12">
                            <h4 className="mb-3">{Title ?? "Heat map"}</h4>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-12">
                            <select
                                value={inputFloorPlan}
                                onChange={(e) => setInputFloorPlan(Number(e.target.value))}
                                className="form-select px-2 py-1 mb-3 select-hover"
                                placeholder="Select Option"
                            >
                                <option value={0}>Select Floor Plan</option>
                                {filteredFloorplan && filteredFloorplan?.length > 0 && (
                                    [...filteredFloorplan]?.sort((a, b) => a?.Location?.Name?.localeCompare(b?.Location?.Name))?.map((d, i) =>
                                        <option key={i} value={d?.Id}>
                                            {`${d?.Location?.Name} - ${d?.Name}`}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-12"></div>
                    </div>

                    <div className="main-map-area">
                        {floorPlanMap?.Image === undefined ? (
                            <div className="loader" style={{ height: "350px" }}>
                                <ClipLoader color={`${Theme?.AppThemeColor ? "#" + Theme?.AppThemeColor : "#004195"}`} loading={true} size={100} />
                            </div>
                        ) : floorPlanMap?.Image ? (
                            <img
                                src={floorPlanMap?.Image}
                                onLoad={(e) => {
                                    setSelectedFloorPlan(inputFloorPlan !== 0 ? filteredFloorplan.filter((d) => d?.Id === inputFloorPlan)[0] : {})
                                    setFloorPlanMap((prev) => ({ ...prev, naturalHeight: e?.target?.naturalHeight, naturalWidth: e?.target?.naturalWidth }))
                                }}
                                className="main-map" alt="floor-Plan"
                            />
                        ) : (
                            <div className="w-100" style={{ height: "350px" }} />
                        )}

                        {/* ====================================== Meeting Rooms ====================================== */}
                        {floorPlanMap?.Image !== undefined && floorPlanMap?.Image !== "" && renderImage === "MeetingRoom" &&
                            selectedFloorPlan?.FloorplanMeetingRooms && selectedFloorPlan?.FloorplanMeetingRooms.length > 0 &&
                            selectedFloorPlan?.FloorplanMeetingRooms.map((d, i) => renderMeetingTooltip(d, i, meetingHeatmapData))
                        }
                        {/* -------------------------------------- Meeting Rooms -------------------------------------- */}

                        {/* ====================================== Desk Seats ====================================== */}
                        {floorPlanMap?.Image !== undefined && floorPlanMap?.Image !== "" && renderImage === "DeskSeats" &&
                            selectedFloorPlan?.FloorplanAreas && selectedFloorPlan?.FloorplanAreas.length > 0 &&
                            selectedFloorPlan?.FloorplanAreas.map((d, i) => renderDeskTooltip(d, i, deskHeatmapData))
                        }
                        {/* -------------------------------------- Desk Seats -------------------------------------- */}
                    </div>
                </div>
            </div>

            {/* ========================== Full Screen Modal ========================== */}
            <div className={`card-modal ${isModalOpen ? "open" : ""}`}>
                <div className="modal-header">
                    <button
                        onClick={() => onAreaChange(selectedArea - 1)}
                        className="btn btn-primary px-2 py-1" disabled={isLoading}
                        style={{ backgroundColor: `#${Theme?.AppThemeColor}`, boxShadow: "none", border: "none" }}
                    >
                        <i className="fa-solid fa-arrow-left" />
                    </button>
                    <h3 className="title mx-3 my-0">
                        {selectedFloorPlan && selectedFloorPlan?.FloorplanAreas && selectedFloorPlan?.FloorplanAreas[selectedArea] && (
                            selectedFloorPlan?.FloorplanAreas[selectedArea]?.Area?.Name ?? ""
                        )}
                    </h3>
                    <button
                        onClick={() => onAreaChange(selectedArea + 1)}
                        className="btn btn-primary px-2 py-1" disabled={isLoading}
                        style={{ backgroundColor: `#${Theme?.AppThemeColor}`, boxShadow: "none", border: "none" }}
                    >
                        <i className="fa-solid fa-arrow-right" />
                    </button>
                    <i
                        onClick={() => {
                            setIsModalOpen(false)
                            setSelectedArea(undefined)
                        }}
                        className="modal-close fa-solid fa-xmark"
                    />
                </div>

                <div className="modal-body">
                    {areaMap?.Image === undefined ? (
                        <div className="map-loader">
                            <ClipLoader color={`${Theme?.AppThemeColor ? "#" + Theme?.AppThemeColor : "#004195"}`} loading={true} size={100} />
                        </div>
                    ) : areaMap?.Image && (
                        <div className="map-area">
                            <img
                                src={areaMap?.Image}
                                onLoad={(e) => setAreaMap({ ...areaMap, naturalHeight: e?.target?.naturalHeight, naturalWidth: e?.target?.naturalWidth })}
                                className="map" alt="floor-Plan"
                            />
                            {areaMap?.Image !== undefined && allSeats && allSeats?.length > 0 && (
                                allSeats?.map((d, i) => renderSeat(d, i, seatsData))
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
